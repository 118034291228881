import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import Display from '../components/Display';
import Search from '../components/Search';
import Header from '../components/Header'
import Footer from '../components/Footer';
import GroupSearch from '../components/GroupSearch';
import io from 'socket.io-client'

function check(socket, groupID){
  socket.emit('found', {group: groupID})
}

function Groups(){

    const [chosenrestaurant, setrestaurant] = useState({
        name: "Let's search for a restaurants !!!",
        link: ''
      });
      const [groupID, setGroupID] = useState(null);
      const [venueList, setVenueList] = useState({
        venues: null
      })
      const [socket, setSocket] = useState(null)

      const [send, setSend] = useState(null)
      useEffect(()=>{
        if (venueList.venues != null && socket == null){
          setSocket(io('https://choosemyfood.herokuapp.com'))
          console.log('setting up connection')
          
        }
      })
      useEffect(()=>{
        console.log(socket)
        if(!socket){
          console.log('no socket')
          return
        }
        else{
          setSend(setInterval(check(socket, groupID), 1000))
          console.log('joining room')
          socket.emit('room', groupID)

          socket.on('disconnect', ()=>{
            console.log('connection lost')
          })
          socket.on('selected', (data)=>{
  
            var selectedVenue = null
  
            if(venueList.venues != null){
              for (var i = 0; i < venueList.venues.length; i++){
                if (venueList.venues[i].id == data.selected){
                  selectedVenue = venueList.venues[i]
                  break
                }
              }
  
              setrestaurant({
                name: selectedVenue.name,
                link: selectedVenue.id
              })
    
              setVenueList({venues: null})
            }
          })
        }

        socket.on('connect', ()=>{
          console.log(groupID)
          socket.emit('room', groupID)
          console.log('joining room')
        })
        
      }, [socket])
      return (
        <div className="App">
          <div className = 'headerBox'>
              <a className = 'homeLink' href = '/'>
              <Header title={'Choose my Food'}/>
              </a>
            <div className = 'divider'/>
          </div>
          
          <div className = 'interface'>
              
            <Display restaurant = {chosenrestaurant} venueList = {venueList} groupID = {groupID}/>
            
            <div className = 'queryItems'>
                <GroupSearch updateID = {setGroupID} groupID = {groupID} updateVenueList = {setVenueList}/>
                <Search updaterestaurant = {setrestaurant} updateGroupID = {setGroupID} updateVenueList = {setVenueList} group={true}/>
            </div>
    
          </div>
    
          <div className = 'footerBox'>
            <Footer/>
          </div>
          
        </div>
      );
}

export default Groups;