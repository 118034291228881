import React, {useState} from 'react';
import {Link} from 'react-router-dom'
import Display from '../components/Display';
import Search from '../components/Search';
import Header from '../components/Header'
import Footer from '../components/Footer';

function LandingPage(){
    const [chosenrestaurant, setrestaurant] = useState({
        name: "Let's search for a restaurants !!!",
        link: ''
      });
      return (
        <div className="App">
          <div className = 'headerBox'>
            <Header title={'Choose my Food'}/>
            <div className = 'divider'/>
            <Link className = 'formStyle link tooltip expand' to = '/groups' data-title='Got a group use me'>Groups</Link>
          </div>
          
          <div className = 'interface'>
    
            <div className = 'restaurant'>
              
              <Display restaurant = {chosenrestaurant}/>
            </div>
    
            <div className = 'queryItems'>
              <Search updaterestaurant = {setrestaurant}/>
            </div>
    
          </div>
    
          <div className = 'footerBox'>
            <Footer/>
          </div>
          
        </div>
      );
}

export default LandingPage;