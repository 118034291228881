import React from 'react'
import './components.min.css'
import poweredBy from '../assets/powered-by-foursquare-grey.png'
import logo from '../assets/choosemyfood.png'

function Header(prop){
    return(
        <div className = 'Header'>
            <div className = 'logoHeader'>
                <img className = 'HeaderLogo' src = {logo}/>
                <div className = 'Title'>
                    {prop.title}
                </div>
            </div>
            <div className = 'imageBox'>
                <img className = 'powered' src = {poweredBy}/>
            </div>
            
        </div>
    );
}

export default Header;