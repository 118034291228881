import React from 'react'
import './components.min.css'

function Footer(props){
    return(
        <div className = 'Footer'>
            <div className = 'information'>
                <div>
                    Built and designed by James Ponce
                </div>
                <div>
                    email: <a className = 'accent' href = 'mailto:ponce800@hotmail.com' subject='ChoosemyFood: <insert reason for email>'>ponce800@hotmail.com</a>
                </div>
                
            </div>

            <div className = 'links'>

            </div>
            
        </div>
    )
}

export default Footer;