import React from 'react';
import LandingPage from './pages/choosemyfood_landing'
import Groups from './pages/groups'
import './App.min.css';

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  Redirect
} from 'react-router-dom'


const clientID = '4MX0PDGEGJYXZNSV0TWVD4QYMEEZZS5PNTJ4YRISGJATU45I';

function App() {
  return(
    <Router>
      <Switch>
        <Route exact path = '/' component = {LandingPage}/>
        <Route exact path = '/groups' component = {Groups}/>
        <Route path = '*'>
          <Redirect to = '/'/>
        </Route>
      </Switch>
    </Router>
  );
  
}

export default App;
