import React, {Component} from 'react';
import Draggable from 'react-draggable'
import ReactDOM from 'react-dom';

const clientID = '4MX0PDGEGJYXZNSV0TWVD4QYMEEZZS5PNTJ4YRISGJATU45I';
//href = {'https://foursquare.com/v/'+this.props.name+'/'+this.props.id+'?ref='+clientID}

class SelectionCard extends Component{
    
    constructor(props){
        super(props)
        this.state = {
            visible: true,
            pos:{
                x: 0,
                y: 0
            }
        }
    }

    handleDrag = (event, ui)=>{
        const {x, y} = this.state.pos;
        this.setState({prevPos: {x,y}})
        this.setState({
            pos: {
                x: x + ui.deltaX,
                y: y + ui.deltaY,
            }
        });
    }

    handleDrop = (event, ui)=>{ 
        if (this.state.pos.x > 100){
            this.setState({visible: false})
            var url = 'https://choosemyfood.herokuapp.com/api/likevenue?venueID='+ this.props.id + '&groupID=' + this.props.groupID
            fetch(url,
                {
                    method: 'GET'
                })
            return
        }
        if(this.state.pos.x < -100){
            this.setState({visible: false})
            return
        }
        
        this.setState({pos: {x: 0,y: 0}})
        
    }

    render(){

        var returnValue;
        if(this.state.visible){
            returnValue = 
            
            <Draggable position = {this.state.pos } onDrag={this.handleDrag} onStop = {this.handleDrop}>
                <div className = 'card' style = {{zIndex: this.props.index}}>
                        <div className = 'result'>
                            <a className = 'noLink' >
                                <h1>
                                    {this.props.name}
                                </h1>
                            </a>
                                
                        </div>
                </div>
            </Draggable>
        }
        else{
            returnValue = null;
        }

        return(
            returnValue
        )
    }


}

export default SelectionCard