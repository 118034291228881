    import React, {Component} from 'react';
    import Draggable from 'react-draggable'
    import ReactDOM from 'react-dom';
    import SelectionCard from './selectionCard'

const clientID = '4MX0PDGEGJYXZNSV0TWVD4QYMEEZZS5PNTJ4YRISGJATU45I';

class Display extends Component {
    
    constructor(props){
        super(props)

        this.state = {
            element: [],
            pos:{
                x: 0,
                y: 0
            },
            prevPos:{
                x: 0,
                y: 0
            },
            visible: true,
            rerendered: false
        }
    }

    handleDrag = (event, ui)=>{
        console.log(ui)
        const {x, y} = this.state.pos;
        this.setState({prevPos: {x,y}})
        this.setState({
            pos: {
                x: x + ui.deltaX,
                y: y + ui.deltaY,
            }
        }, ()=>console.log(this.state.pos));
    }

    handleDrop = (event, ui)=>{
        if (this.state.pos.x > 100){
            this.setState({visible: false})
        }
        else{
            this.setState({pos: {x: 0,y: 0}})
        }
    }

    renderElements = ()=>{

        if (!this.state.rerendered){
            if(this.props.venueList.venues != null){
                var elements = {elements:[]}
                for(var i = 0; i < this.props.venueList.venues.length; i++){
                    elements.elements=[...elements.elements, {pos:{x:0, y:0}, visible:true}]
                }
    
                this.setState({element: elements.elements})
            }
        }
        
    }

    render(){
        return (
            <div className='restaurant'>
            {
                (this.props.venueList != null && this.props.venueList.venues != null)?(
                    this.props.venueList.venues.map(venue=>(<SelectionCard name={venue.name} id = {venue.id} index = {venue.internalID} groupID = {this.props.groupID}/>))
                    )
                :
                (
                    
                        <div className = 'result'>
                            <a className = {(this.props.restaurant.link == '' ? 'noLink': 'yesLink')}  href={'https://foursquare.com/v/' + this.props.restaurant.name + '/' + this.props.restaurant.link + '?ref=' + clientID}>
                                <h1>{this.props.restaurant.name}</h1>
                            </a>
                        </div>
                )
                }

            </div>
            
            
        );
    }
}

export default Display;
