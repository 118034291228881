import React, {Component} from 'react'

import './components.min.css'

var socket;

class GroupSearch extends Component{
    
    constructor(props){
        super(props)
        socket = null//io('https://choosemyfood.herokuapp.com/');

        this.state = {
            'groupID': this.props.groupID
        }

        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event){
        this.props.updateID(event.target.value)
    }

    handleClick(event){
        event.preventDefault()

        if(this.props.groupID == ''){
            alert("groupID can't be null")
            return
        }

        var getURL = 'https://choosemyfood.herokuapp.com/api/findGroup?groupID=' + this.props.groupID

        try{    
            fetch(getURL,
                {
                    method: 'GET'
                }).then(response => {
                    if(!response.ok){
                        alert('an error has arose. Did you type in the groupID correct?')
                        return null
                    }

                    return response.json()
                })
                .then(response => {
                    if(response == null){
                        this.props.updateVenueList('')
                        return
                    }

                    this.props.updateVenueList({venues: response.venueList});
                    //console.log(response)
    
                })
        }
        catch (error)
        {
            console.log(error)
        }
    }
    render(){
        return(
            <div className = 'groupSearch'>
                <form>
                    <input className = 'textInput' type = 'text' placeholder = { 'enter a groupID'} value = {this.props.groupID} onChange = {this.handleChange}></input>
                    <input type = 'submit' onClick = {this.handleClick} className = 'button' value = 'find'/>
                </form>
            </div>
        );
    }
}

export default GroupSearch;