import React, {Component} from 'react';
import './components.min.css'
import food from '../cateories.json'

const clientID = '4MX0PDGEGJYXZNSV0TWVD4QYMEEZZS5PNTJ4YRISGJATU45I'


class Search extends Component {

    constructor(props){
        super(props);
        this.state = {
            value: '',
            dropdown: true,
            searchValues: [],
            radius: 10000,
            mixNmatch: false,
            location:null,
            search: null,

        };

        

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.showList = this.showList.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    removeItem(index){
        var beforeRemoveList = this.state.searchValues;

        beforeRemoveList.splice(index, 1);

        this.setState({searchValues: beforeRemoveList})
    }

    handleChange(event){

        if(event.target.name == 'mixNmatch' || event.target.name == 'radius'){
            if (event.target.name === 'mixNmatch'){
                var newValue = event.target.value;
                this.setState({mixNmatch: !this.state.mixNmatch});
            }
            else{
                var newValue = event.target.value == 0?(10000):(event.target.value * 1.609344);
                this.setState({radius: newValue});
            }
        }
        else{
            var found = this.state.searchValues.indexOf(event.target.value);
            var beforeSearch = this.state.searchValues;
            
            console.log(found);
            if (found === -1){
                beforeSearch.push(event.target.value);
            }
            else{
                beforeSearch.splice(found, 1);
            }

            this.setState({searchValues: beforeSearch});
        }
    }

    handleSearch(event){
        this.setState({search: event.target.value})
    }

    componentDidMount = () =>{
        if(navigator.geolocation){
            navigator.geolocation.getCurrentPosition(
                // Success callback
                function(position) {

                    /*
                    position is an object containing various information about
                    the acquired device location:

                    position = {
                        coords: {
                            latitude - Geographical latitude in decimal degrees.
                            longitude - Geographical longitude in decimal degrees. 
                            altitude - Height in meters relative to sea level.
                            accuracy - Possible error margin for the coordinates in meters. 
                            altitudeAccuracy - Possible error margin for the altitude in meters. 
                            heading - The direction of the device in degrees relative to north. 
                            speed - The velocity of the device in meters per second.
                        }
                        timestamp - The time at which the location was retrieved.
                    }
                    */
                   const location = position;

                   console.log(position);

                   this.setState({location});

                }.bind(this),

                // Optional error callback
                function(error){

                    console.log('ERROR');

                }
            );
        }
    }

    handleSubmit(event){
        
        event.preventDefault();
        console.log(event.target);

        var searchParams = '';

        console.log(this.state);
        
        console.log(this.state.searchValues);
        if (this.state.searchValues.length === 0){
            searchParams = food.id;
        }
        else{
            for(var i = 0; i < this.state.searchValues.length; i++){
                searchParams+=this.state.searchValues[i]+','
            }

             searchParams = searchParams.slice(0, -1);
        }

        

        var URL = 'https://api.foursquare.com/v2/venues/search?client_id=' + clientID + '&client_secret=DXWZGA5YA0UIEGUYBL3H4GZE3HSPRLBGGHZ2JZJ4N0AWQXUO&v=20190425&ll='+this.state.location.coords.latitude +','+ this.state.location.coords.longitude +'&radius=' + (this.state.radius==null?(10000): (this.state.radius)) +'&categoryId=' + searchParams+'&limit=50';
        fetch(URL, 
        {
            method: 'GET'
        }).then(response => response.json())
        .then(response=>{
            console.log(response.response.venues)
            
            if (response.response.venues.length === 0){
                alert('No restaurants within the desired radius');
                return;
            }

            if (this.props.group != null){

                //communicate to the backend.
                var venuesList = response.response.venues
                for(var i = 0; i < venuesList.length; i++){
                    venuesList[i].internalID = i;
                }
                URL = 'https://choosemyfood.herokuapp.com/api/generategroup'
                fetch(URL,
                    {
                        method: 'POST',
                        headers: {'content-type': 'application/json'},
                        body: JSON.stringify({venueList: venuesList})

                }).then(response=>{
                    if(!response.ok){
                        return null
                    }

                    return response.json()
                })
                .then(response=>{
                    if(response == null){
                        return
                    }

                    this.props.updateGroupID(response.ID)
                    this.props.updateVenueList({venues: venuesList})

                    alert("Your group ID is: "+ response.ID + " which may also be found in the groupID box")
                })
            }
            else{
                let restaurant = Math.floor(Math.random()*Math.floor(response.response.venues.length));
                console.log(restaurant)

                this.props.updaterestaurant({
                    name: response.response.venues[restaurant].name,
                    link: response.response.venues[restaurant].id
                });
            }

            
        })
    }

    showList(event){
        console.log(event.target)
        this.setState({dropdown: !this.state.dropdown});
    }

    render(){
        return (
            
                <form className = 'parameters' onSubmit = {this.handleSubmit}>
                    <input type='submit' className = 'formStyle button' value ='Search!!!'/>
                    <div>
                        <label>radius: </label>
                        <input className = 'textInput' type = 'number' name = 'radius' onChange = {this.handleChange} min = '1' max = '62137.1192'/>
                    </div>
                    <div>
                        <label >mixNmatch
                            <input type = 'checkBox' name = 'mixNmatch' onChange = {this.handleChange}/>
                        </label>
                    </div>
                    <div className = 'categoryList'>
                        <div className = 'dropControl'>
                            <label onClick = {this.showList}>Categories:</label>
                            <div className = 'arrow'></div>
                        </div>
                        
                        {
                            this.state.dropdown?(<input type='text' className = 'textInput search' onChange = {this.handleSearch} value={this.state.search} placeHolder='search categories'/>):(null)
                        }
                        <div className = 'dropBox'>
                        {
                            this.state.dropdown?(
                                food.categories.map(category=>(
                                    this.state.search == null || category.name.toLowerCase().includes(this.state.search.toLowerCase()) ?
                                    (
                                        <div className = 'categorySelection'>
                                            <label onChange = {this.handleChange}>
                                                <input type = 'checkbox' name = {category.name} value = {category.id} checked={this.state.searchValues.includes(category.id)? true: false} />
                                                {category.name}
                                            </label>
                                        </div>
                                    ):(null)
                                    
                                ))
                            )
                            :(null)
                        }
                        </div>
                        
                    </div>
                    
                </form>
        );
    }
}

export default Search;
